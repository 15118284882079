import * as React from "react";
import { useState, useEffect } from "react";
import type { HeadFC } from "gatsby";
import { Layout, ProgressRing } from "../components";

import "../assets/scss/pages/staking.scss";

import { Swiper, SwiperProps, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation, Thumbs } from "swiper";

// Import Swiper styles
import 'swiper/scss';
import 'swiper/scss/autoplay';

import { buildSwiperSlides, IStakingLayer, OPENSEA_LAYERS_MARKET, OPENSEA_TEARS_MARKET, StakingRewards, StakingRewardsDays, StakingRounds, StakingTiers, StakingTiersDays, TearTypes } from "../helpers";

SwiperCore.use([Autoplay, Navigation, Thumbs]);

const StakingPage = () => {

	const [collectionNFTCount, setCollectionNFTCount] = useState('0');

	const [availableLayers, setAvailableLayers] = useState<Array<IStakingLayer>>([]);
	const [selectedLayer, setSelectedLayer] = useState<IStakingLayer | false>(false);
	const [selectedLayerIndex, setSelectedLayerIndex] = useState(0);

	const [thumbsSwiper, setThumbsSwiper] = useState<SwiperCore | null>(null);
	// const [layersSwiper, setLayersSwiper] = useState<SwiperCore | null>(null);

	useEffect(() => {
		let nft_count = 2803;
		setCollectionNFTCount((nft_count / 1000).toFixed(3).replace(/\./, ','));


		let _TEMP_available_layers: Array<IStakingLayer> = [];

		//? Set a temporary array of available layers
		if (location.search.indexOf('layers') !== -1) {
			for (let i = 0; i < 10; i++) {
				let _layer: IStakingLayer = {
					title: Math.floor(Math.random() * 2803 + 1).toString(),
					traits: {
						background: {
							value: 'Regular',
							count: Math.floor(Math.random() * 2803 + 1),
							isRare: Math.random() > 0.5,
						},
						border: {
							value: 'Regular',
							count: Math.floor(Math.random() * 2803 + 1),
							isRare: Math.random() > 0.5,
						},
					},
					staked: Math.random() > 0.5,
					round: Math.floor(Math.random() * 2) + 1,
					daysStaking: 0,
					daysStakingStreak: 0,
					daysUntilNextStatus: 0,
					img: '/assets/img/temp/canvas_blank_xs.png',
					img2x: '/assets/img/temp/canvas_blank_xs@2x.png',
					sources: {
						768: {
							img: '/assets/img/temp/canvas_blank_md.png',
							img2x: '/assets/img/temp/canvas_blank_md@2x.png',
						},
						1200: {
							img: '/assets/img/temp/canvas_blank_xl.png',
							img2x: '/assets/img/temp/canvas_blank_xl@2x.png',
						},
					},
				};
				_layer.rewardLevel = _layer.round < 2 ? Math.floor(Math.random() * StakingRewards.length + 1) : StakingRewards.length;
				_layer.tierLevel = _layer.round === 2 ? Math.floor(Math.random() * StakingTiers.length + 1) : 0;

				_layer.tearsStaking = [];
				for (let ii = 0; ii < _layer.rewardLevel; ii++) {
					_layer.tearsStaking.push(Math.random() >= 0.5 ? 'beta' : 'alpha');
				}

				switch (_layer.round) {
					case 1:
						_layer.daysStaking = Math.floor(Math.random() * StakingRewardsDays[_layer.rewardLevel - 1]);
						_layer.daysUntilNextStatus = StakingRewardsDays[_layer.rewardLevel - 1] - _layer.daysStaking;
						break;
					case 2:
						_layer.daysStaking = Math.floor(Math.random() * StakingTiersDays[_layer.tierLevel - 1]);
						_layer.daysUntilNextStatus = StakingTiersDays[_layer.tierLevel - 1] - _layer.daysStaking;
						break;
				}

				_layer.daysStakingStreak = _layer.daysStaking;

				_TEMP_available_layers.push(_layer);
			}
		}

		setAvailableLayers(_TEMP_available_layers);
	}, []);

	let isOnSlideChange = false;
	const onSlideChange = (swiper: SwiperCore) => {
		// console.log('onSlideChange', { isOnMiniSlideChange, swiper });
		if (isOnMiniSlideChange) {
			isOnMiniSlideChange = false;
			return;
		}

		setSelectedLayerIndex(swiper.realIndex);
		setSelectedLayer(availableLayers[swiper.realIndex]);
		isOnSlideChange = true;
		// thumbsSwiper?.slideTo(swiper.realIndex);
	};

	let isOnMiniSlideChange = false;
	const onMiniSlideChange = (swiper: SwiperCore) => {
		// console.log('onMiniSlideChange', { isOnSlideChange, swiper });
		if (isOnSlideChange) {
			isOnSlideChange = false;
			return;
		}

		// setSelectedLayerIndex(swiper.realIndex);
		// setSelectedLayer(availableLayers[swiper.realIndex]);
		isOnMiniSlideChange = true;
		// layersSwiper?.slideTo(swiper.realIndex);
	};

	const rewardsXsSliderOptions: SwiperProps = {
		enabled: true,
		slidesPerView: 2.1,
		spaceBetween: 10,
		breakpoints: {
			768: {
				enabled: false,
			},
		},
	};

	const rewardsXsSliderSlides: Array<{title: string, claimed: boolean}> = [
		{
			title: 'Upcoming reward name long name',
			claimed: Math.random() >= 0.75,
		},
		{
			title: 'Reward name long name',
			claimed: Math.random() >= 0.75,
		},
		{
			title: 'Reward name long name',
			claimed: Math.random() >= 0.75,
		},
	];

	const layersSliderOptions: SwiperProps = {
		slidesPerView: 1.3,
		centeredSlides: true,
		breakpoints: {
			576: {
				slidesPerView: 2.5,
			},
			768: {
				slidesPerView: 1,
			}
		},
		onSlideChange: swiper => onSlideChange(swiper),
		onInit: (swiper) => {
			// setLayersSwiper(swiper);
			onSlideChange(swiper);
		},
		thumbs: { swiper: thumbsSwiper }
	};

	const layersMiniSliderOptions: SwiperProps = {
		watchSlidesProgress: true,
		onSwiper: setThumbsSwiper,
		direction: 'vertical',
		slidesPerView: 3.2,
		navigation: {
			nextEl: '.slide-preview .swiper-button-next',
			prevEl: '.slide-preview .swiper-button-prev',
		},

		// onSlideChange: (swiper) => onMiniSlideChange(swiper),
		onInit: (swiper) => {
			setThumbsSwiper(swiper);
			onMiniSlideChange(swiper);
		},
	}

	return (
		<Layout nftSliderTextColor={'white'} nftSliderTitle={`${collectionNFTCount} uniquely generated artworks to reveal`}>

			<div className={'content'}>

				<section id={'revealing_info'} className={''}>
					<picture className={'responsive bg-texture'}>
						<source srcSet={'/assets/img/bkgs/bkg_reveal_top_xxl.png 1x, /assets/img/bkgs/bkg_reveal_top_xxl@2x.png 2x'} media={'(min-width: 1200px'} />
						<source srcSet={'/assets/img/bkgs/bkg_reveal_top_xl.png 1x, /assets/img/bkgs/bkg_reveal_top_xl@2x.png 2x'} media={'(min-width: 1200px'} />
						<source srcSet={'/assets/img/bkgs/bkg_reveal_top_lg.png 1x, /assets/img/bkgs/bkg_reveal_top_lg@2x.png 2x'} media={'(min-width: 992px'} />
						<source srcSet={'/assets/img/bkgs/bkg_reveal_top_md.png 1x, /assets/img/bkgs/bkg_reveal_top_md@2x.png 2x'} media={'(min-width: 768px'} />
						<img srcSet={'/assets/img/bkgs/bkg_reveal_top_xs.png 1x, /assets/img/bkgs/bkg_reveal_top_xs@2x.png 2x'} src={'/assets/img/bkgs/bkg_reveal_top_xs.png'} />
					</picture>

					<div className={'container-fluid'}>
						<div className={'row'}>
							<div className={'col-12'}>

								<input type={'checkbox'} id={'reveal-info-content'} hidden={true} />

								<div className={'top-content row'}>
									<div className={'col-12 col-xl-10 offset-xl-1'}>
										<div className={'d-flex justify-content-between'}>
											<h3 className={'title'}>
												Staking
											</h3>
											<div className={'btn info-dropdown'}>
												<label htmlFor="reveal-info-content">
													<span className="txt">Info</span>
													<span className="icon"></span>
												</label>
											</div>
										</div>
									</div>
								</div>

								<div className={'info-content'}>
									<div className={'row'}>

										<div className={'instructions col-12 col-lg-10 offset-lg-1 col-xl-7 offset-xl-1'}>
											<div className={'row'}>
												<div className={'col-6 col-md-5'}>
													<div className={'row round1'}>

														<h4 className={'col-12 title'}>
															ROUND 1
															<span>UNREVEALED</span>
														</h4>

														<div className={'step'}>
															<div className={'txt'}>120 Days</div>
															<div className={'icon'}>
																<img src="/assets/img/icons/icon-equal.svg" alt="1 Tear" width={25} height={25} />
															</div>
															<div className={'txt'}>1st Tear</div>
														</div>

														<div className={'step'}>
															<div className={'txt'}>+ 70 Days</div>
															<div className={'icon'}>
																<img src="/assets/img/icons/icon-equal.svg" alt="2 Tears" width={25} height={25} />
															</div>
															<div className={'txt'}>2nd Tear</div>
														</div>

														<div className={'step'}>
															<div className={'txt'}>+ 40 Days</div>
															<div className={'icon'}>
																<img src="/assets/img/icons/icon-equal.svg" alt="3 Tears" width={25} height={25} />
															</div>
															<div className={'txt'}>3rd Tear</div>
														</div>

													</div>
												</div>
												<div className={'col-6 col-md-7'}>
													<div className={'row round2'}>

														<h4 className={'col-12 title'}>
															ROUND 2
															<span>REVEALED</span>
														</h4>

														<div className={'row'}>
															<div className={'col-12 col-md-6'}>
																<div className={'step'}>
																	<div className={'icon'}>
																		<img src="/assets/img/icons/icon-bronze.svg" alt="Bronze Holder" width={30} height={30} />
																	</div>
																	<div className={'txt'}>
																		<span>Bronze Holder</span>
																		<span>+ 60 Days</span>
																	</div>
																</div>

																<div className={'step'}>
																	<div className={'icon'}>
																		<img src="/assets/img/icons/icon-silver.svg" alt="Silver Holder" width={30} height={30} />
																	</div>
																	<div className={'txt'}>
																		<span>Silver Holder</span>
																		<span>+ 60 Days</span>
																	</div>
																</div>

																<div className={'step'}>
																	<div className={'icon'}>
																		<img src="/assets/img/icons/icon-gold.svg" alt="Gold Holder" width={30} height={30} />
																	</div>
																	<div className={'txt'}>
																		<span>Gold Holder</span>
																		<span>+ 60 Days</span>
																	</div>
																</div>
															</div>
															<div className={'col-12 col-md-6'}>
																<div className={'step'}>
																	<div className={'icon'}>
																		<img src="/assets/img/icons/icon-platinum.svg" alt="Platinum Holder" width={30} height={30} />
																	</div>
																	<div className={'txt'}>
																		<span>Platinum Holder</span>
																		<span>+ 60 Days</span>
																	</div>
																</div>

																<div className={'step'}>
																	<div className={'icon'}>
																		<img src="/assets/img/icons/icon-diamond.svg" alt="Diamond Holder" width={30} height={30} />
																	</div>
																	<div className={'txt'}>
																		<span>Diamond Holder</span>
																		<span>+ 116 Days</span>
																	</div>
																</div>

															</div>
														</div>

													</div>
												</div>
											</div>
										</div>

										<div className={'more-info cta col-12 col-xl-2 offset-xl-1'}>
											<h4 className={'title'}>
												Need MORE INFO?
											</h4>
											<div className="row">
												<div className="col-6 offset-3 col-md-2 offset-md-5 col-xl-8 offset-xl-0">
													<div className={'faqs-btn'}>
														<a className="btn" href={'#faqs_block'}>FAQs</a>
													</div>
												</div>
											</div>
										</div>

										<div className={'cta col-12 col-xl-4 offset-xl-4'}>
											<div className={'im-ready cta-btn'}>
												<a className="btn" href={'#interface'}>
													I'M READY, LET'S START
													<span className={'cta-arrow'}>
														<img src={'/assets/img/arrows/arrow_cta_down.svg'} />
													</span>
												</a>
											</div>
										</div>

									</div>
								</div>
							</div>
						</div>
					</div>
				</section>

				<section id={'interface'} className={''}>
					{/* XS */}
					<div className={'rewards-xs d-md-none'}>
						<picture className={'responsive bg-texture'}>
							<img srcSet={'/assets/img/bkgs/bkg_reveal_top_xs.png 1x, /assets/img/bkgs/bkg_reveal_top_xs@2x.png 2x'} src={'/assets/img/bkgs/bkg_reveal_top_xs.png'} />
						</picture>
						<div className={'container-fluid'}>
							{availableLayers.length ?
								<>
									<div className={'row'}>
										<div className={'col-12'}>
											<h3 className={'rewards-title'}>REWARDS:</h3>
										</div>
									</div>
									<Swiper {...rewardsXsSliderOptions}>
										{rewardsXsSliderSlides.map((slide, i) =>
											<SwiperSlide key={i}>
												<div className={'rewards-item'}>
													{i === 0 ? <div className={'pretitle'}>Coming up: 08:09:10</div> : null}
													<div className={'title'}>{slide.title}</div>
													<button className={`btn${slide.claimed ? ' claimed' : ''}`}>CLAIM</button>
												</div>
											</SwiperSlide>
										)}
									</Swiper>
								</>
							:
							<div className={'row'}>
								<div className={'col-10 offset-1'}>
									<h3 className={'rewards-title'}>REWARDS:</h3>
									<div className={'no-layers'}>Receive rewards for staking your layers</div>
								</div>
							</div>}
						</div>
					</div>
					{/* XS */}

					<div className={'layers-customize container-fluid'}>
						<div className={'row'}>

							<div className={'col-12 col-md-3 col-xl-2 offset-xl-1 d-flex flex-column'}>

								{/* MD */}
								<div className={'section staking-status row ' + (availableLayers.length ? 'order-0' : 'order-1')}>
									<div className={'col-10 offset-1 col-md-12 offset-md-0'}>
										<h3 className={'interface-title'}>LAYERS <span className={'d-inline-block'}>#{selectedLayer ? selectedLayer.title : '--'}</span></h3>
										<p>Layer <strong>{availableLayers.length ? selectedLayerIndex + 1 : 0}/{availableLayers.length}</strong> selected</p>
									</div>
									<div className={'col-10 offset-1 col-md-12 offset-md-0'}>
										<h3 className={'section-title'}>STAKING STATUS</h3>
									</div>
									<div className={'col-5 offset-1 col-md-12 offset-md-0 staking-round'}>
										<h3 className={'round'}>
											{selectedLayer ? StakingRounds[selectedLayer.round - 1] + ' / ' + (selectedLayer.round === 2 ? 'Revealed' : 'Unrevealed') : '--'}
										</h3>

										{!selectedLayer || selectedLayer.round === 1 ?
											<>
												<h4 className={'section-subtitle'}>Tears earned until now:</h4>
												<div className={'tear-types'}>
													{selectedLayer && selectedLayer.tearsStaking && selectedLayer.tearsStaking[0]
														? <span className={'tear-type active'}>{TearTypes[selectedLayer.tearsStaking[0]]}</span>
														: <span className={'tear-type'}>--</span>
													}
													{selectedLayer && selectedLayer.tearsStaking && selectedLayer.tearsStaking[1]
														? <span className={'tear-type active'}>{TearTypes[selectedLayer.tearsStaking[1]]}</span>
														: <span className={'tear-type'}>--</span>
													}
													{selectedLayer && selectedLayer.tearsStaking && selectedLayer.tearsStaking[2]
														? <span className={'tear-type active'}>{TearTypes[selectedLayer.tearsStaking[2]]}</span>
														: <span className={'tear-type'}>--</span>
													}
												</div>
											</>
										:
										<div className={'tier-type'}>

											{selectedLayer.tierLevel ?
												<div className={'icon'}>
													<img src={`/assets/img/icons/icon-${StakingTiers[selectedLayer.tierLevel - 1].toLowerCase()}.svg`} alt={`${StakingTiers[selectedLayer.tierLevel - 1]} Holder`} />
												</div>
												: null}
											<div className={'txt'}>
												<span>
													{selectedLayer.tierLevel ? StakingTiers[selectedLayer.tierLevel - 1] : '--'}
												</span>
											</div>
										</div>}

									</div>
									<div className={'col-5 col-md-12'}>
										<div className={'row'}>
											<div className={'col-9'}><h4 className={'section-subtitle'}>Staking time: </h4></div>
											<div className={'staking-days col-3'}>{selectedLayer ? selectedLayer.daysStaking : '--'}</div>
										</div>
										<div className={'row'}>
											<div className={'col-9'}><h4 className={'section-subtitle'}>Days until next status: </h4></div>
											<div className={'staking-days col-3'}>{selectedLayer ? selectedLayer.daysUntilNextStatus : '--'}</div>
										</div>
										<div className={'row'}>
											<div className={'col-9'}><h4 className={'section-subtitle'}>Lifetime staking streak: </h4></div>
											<div className={'staking-days col-3'}>{selectedLayer ? selectedLayer.daysStakingStreak : '--'}</div>
										</div>
									</div>
								</div>

								<div className={'section rewards-md d-none d-md-block ' + (availableLayers.length ? 'order-2' : 'order-3')}>
									<h3 className={'section-title'}>REWARDS</h3>
									{availableLayers.length ? rewardsXsSliderSlides.map((slide, i) =>
										<div className={'rewards-item'}>
											{i === 0 ? <div className={'pretitle'}>Coming up: 08:09:10</div> : null}
											<div className={'d-flex align-items-center justify-content-between'}>
												<div className={'title'}>{slide.title}</div>
												<div className={'d-flex align-items-center'}>
													<button className={`btn${slide.claimed ? ' claimed' : ''}`}>CLAIM</button>
												</div>
											</div>
										</div>
									)
									: <div className={'no-layers'}>Receive rewards for staking your layers</div>}
								</div>

								<div className={'section opensea-btns d-none d-md-block ' + (availableLayers.length ? 'order-3' : 'order-0')}>
									<div className={'row'}>
										<div className={'opensea-btn-holder col-12'}>
											<div className={'opensea-btn'}>
												<a href={OPENSEA_TEARS_MARKET} target={'_blank'} rel={'noopener'} className={'btn has-icon justify-content-center'}>
													<img src={'/assets/img/icons/icon_opensea_fff.svg'} />
													<span className={'text'}>BUY TEARS</span>
												</a>
											</div>
										</div>
										<div className={'opensea-btn-holder col-12'}>
											<div className={'opensea-btn'}>
												<a href={OPENSEA_LAYERS_MARKET} target={'_blank'} rel={'noopener'} className={'btn has-icon justify-content-center'}>
													<img src={'/assets/img/icons/icon_opensea_fff.svg'} />
													<span className={'text'}>BUY LAYERS</span>
												</a>
											</div>
										</div>
									</div>
								</div>
								{/* MD */}

							</div>

							<div className={'col-12 col-md-9 col-xl-8 layers-slider ' + (availableLayers.length ? 'with-layers' : 'no-layers')}>

								<div className={'slider d-flex justify-content-between align-items-start'}>

									{availableLayers.length ? <div className={'slide-view'}>

										<div className={'slider-content'}>
											<Swiper {...layersSliderOptions}>
												{buildSwiperSlides(availableLayers.map((layer, index) => {
													const outerProgress = layer.round == 1 && layer.rewardLevel ? layer.daysUntilNextStatus / StakingRewardsDays[layer.rewardLevel - 1] * 100 : (layer.round == 2 ? 100 : 0);
													const innerProgress = layer.round == 2 && layer.tierLevel ? layer.daysUntilNextStatus / StakingTiersDays[layer.tierLevel - 1] * 100 : 0;
													const layerReward = layer.round == 1 && layer.rewardLevel ? StakingRewards[layer.rewardLevel - 1] : (layer.round == 2 && layer.tierLevel ? StakingTiers[layer.tierLevel - 1] : '--');

													console.log({ layer, outerProgress, innerProgress, layerReward });

													{/* Staking Step Chart - START */ }
													layer.children = (
														<div className={'chart-container'}>
															<div className="chart-staking-step">

																<div className="step-circle-outer">
																	<ProgressRing radius={105} stroke={10} progress={outerProgress} color={'#ff0'} />
																</div>

																<div className="step-circle-inner">
																	<ProgressRing radius={105 - 10} stroke={10} progress={innerProgress} color={'#ff0'} />
																</div>

																<div className="status">
																	<div className={'icon ' + (layer.staked ? 'locked' : 'unlocked')}></div>
																	<div className="txt">{layer.staked ? 'Stacked' : 'Unstaked'}</div>
																</div>

																<div className="step">
																	<div className="txt-reward">{layerReward}</div>
																	<div className="txt-round">Round {layer.round} / {layer.round === 2 ? 'Revealed' : 'Unrevealed'}</div>
																</div>

																<button className="btn">{layer.staked ? 'STOP' : 'START'} STAKING</button>

															</div>
														</div>);
													{/* Staking Step Chart - END */ }

													return layer;
												}), false)}
											</Swiper>
										</div>

									</div> : <div className={'slide-view'}>
										<div className={'slide'}>
											<div>
												<img src={'/assets/img/temp/no_layers_placeholder.svg'} />
											</div>
										</div>
										<div className={'slide d-md-none'}>
											<div>
												<img src={'/assets/img/temp/no_layers_placeholder.svg'} />
											</div>
										</div>
										<div className={'slide d-md-none'}>
											<div>
												<img src={'/assets/img/temp/no_layers_placeholder.svg'} />
											</div>
										</div>
										<div className={'get-layer'}>
											<div className={'text'}>
												Get your first from LAYERS market.
											</div>
											<div className={'opensea-btn'}>
												<a href={'#'} target={'_blank'} rel={'noopener'} className={'btn has-icon d-flex'}>
													<img src={'/assets/img/icons/icon_opensea_fff.svg'} />
													<span className={'text'}>Buy Layers</span>
												</a>
											</div>

										</div>

									</div>}


									<div className={'d-none d-md-block slide-preview'}>
										<div className={'layer-count'}>
											<div className={'text'}>You have</div>
											<span className={'counter'}>
												{/* @todo Remove "* 30" below */}
												<span>{availableLayers.length * 30}x</span>
												LAYER{availableLayers.length === 1 ? '' : 'S'}
											</span>
										</div>

										{/* <div className={'fade-overlay'}></div> */}

										{availableLayers.length ?
											<>
												<div className={'swiper-button-prev'}>
													<svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
														<path d="M1.34326 9L8 2.34326L14.6567 9" stroke="white" strokeWidth="2" />
													</svg>
												</div>
												<div className={'mini-slider-content'}>
													<Swiper {...layersMiniSliderOptions}>
														{buildSwiperSlides(availableLayers.map(layer => {
															let _layer = Object.assign({}, layer);
															_layer.children = <div className={'layer-title-container'}>
																<div className={'layer-title'}>#{_layer.title}</div>
																<div className={'layer-tears d-none d-xxl-flex'}>
																	{_layer.tearsStaking && _layer.tearsStaking[0]
																		? <span className={'tear-type active'}>{TearTypes[_layer.tearsStaking[0]]}</span>
																		: <span className={'tear-type'}>--</span>
																	}
																	{_layer.tearsStaking && _layer.tearsStaking[1]
																		? <span className={'tear-type active'}>{TearTypes[_layer.tearsStaking[1]]}</span>
																		: <span className={'tear-type'}>--</span>
																	}
																	{_layer.tearsStaking && _layer.tearsStaking[2]
																		? <span className={'tear-type active'}>{TearTypes[_layer.tearsStaking[2]]}</span>
																		: <span className={'tear-type'}>--</span>
																	}
																</div>
															</div>;
															return _layer;
														}), false)}
													</Swiper>
												</div>
												<div className={'swiper-button-next'}>
													<svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
														<path d="M14.0498 1L7.39307 7.65674L0.736328 1" stroke="white" strokeWidth="2" />
													</svg>
												</div>
											</>
											:
											<div className={'d-xxl-flex'}>
												<div className={'slides d-flex flex-column justify-content-center'}>
													<div className={'slide'}>
														<img src={'/assets/img/temp/no_layers_placeholder.svg'} />
													</div>
													<div className={'slide'}>
														<img src={'/assets/img/temp/no_layers_placeholder.svg'} />
													</div>
													<div className={'slide'}>
														<img src={'/assets/img/temp/no_layers_placeholder.svg'} />
													</div>
													<div className={'slide'}>
														<img src={'/assets/img/temp/no_layers_placeholder.svg'} />
													</div>
													<div className={'slide'}>
														<img src={'/assets/img/temp/no_layers_placeholder.svg'} />
													</div>
													<div className={'slide'}>
														<img src={'/assets/img/temp/no_layers_placeholder.svg'} />
													</div>
												</div>

												<div className={'slides d-none d-xxl-flex flex-column justify-content-center'}>
													<div className={'slide o-0'}>
														<img src={'/assets/img/temp/no_layers_placeholder.svg'} />
														<div className={'layer-info'}>
															<div className={'layer-title-placeholder'}></div>
															<div className={'layer-tears-placeholder'}></div>
														</div>
													</div>
													<div className={'slide o-0'}>
														<img src={'/assets/img/temp/no_layers_placeholder.svg'} />
														<div className={'layer-info'}>
															<div className={'layer-title-placeholder'}></div>
															<div className={'layer-tears-placeholder'}></div>
														</div>
													</div>
													<div className={'slide o-0'}>
														<img src={'/assets/img/temp/no_layers_placeholder.svg'} />
														<div className={'layer-info'}>
															<div className={'layer-title-placeholder'}></div>
															<div className={'layer-tears-placeholder'}></div>
														</div>
													</div>
													<div className={'slide o-0'}>
														<img src={'/assets/img/temp/no_layers_placeholder.svg'} />
														<div className={'layer-info'}>
															<div className={'layer-title-placeholder'}></div>
															<div className={'layer-tears-placeholder'}></div>
														</div>
													</div>
													<div className={'slide o-0'}>
														<img src={'/assets/img/temp/no_layers_placeholder.svg'} />
														<div className={'layer-info'}>
															<div className={'layer-title-placeholder'}></div>
															<div className={'layer-tears-placeholder'}></div>
														</div>
													</div>
													<div className={'slide o-0'}>
														<img src={'/assets/img/temp/no_layers_placeholder.svg'} />
														<div className={'layer-info'}>
															<div className={'layer-title-placeholder'}></div>
															<div className={'layer-tears-placeholder'}></div>
														</div>
													</div>
												</div>
											</div>
										}


									</div>


								</div>

								<div className={'section traits'}>
									<div className={'row row-cols-md-3'}>
										{selectedLayer && selectedLayer.traits?.background ?
											<div className={`trait col-5 offset-1 offset-md-0`}>
												<div className={'desc'}>BACKGROUND</div>
												{selectedLayer.traits.background.isRare ? <span className={'is-rare'}>RARE</span> : null}
												<div className={'value'}>{selectedLayer.traits.background.value}</div>
												<div className={'count'}>{selectedLayer.traits.background.count} {selectedLayer.traits.background.count === 1 ? 'has' : 'have'} this trait</div>
											</div>
											:
											<div className={`trait unrevealed col-5 offset-1 offset-md-0`}>
												<div className={'desc'}>BACKGROUND</div>
												<div className={'value'}>Unrevealed</div>
												<div className={'count'}>-- have this trait</div>
											</div>
										}
										{selectedLayer && selectedLayer.traits?.border ?
											<div className={`trait col-5`}>
												<div className={'desc'}>BORDER</div>
												{selectedLayer.traits?.border?.isRare ? <span className={'is-rare'}>RARE</span> : null}
												<div className={'value'}>{selectedLayer.traits.border.value}</div>
												<div className={'count'}>{selectedLayer.traits.border.count} {selectedLayer.traits.border.count === 1 ? 'has' : 'have'} this trait</div>
											</div>
											:
											<div className={`trait unrevealed col-5`}>
												<div className={'desc'}>BORDER</div>
												<div className={'value'}>Unrevealed</div>
												<div className={'count'}>-- have this trait</div>
											</div>
										}
										{selectedLayer && selectedLayer.traits?.pattern ?
											<div className={`trait col-5 offset-1 offset-md-0`}>
												<div className={'desc'}>PATTERN</div>
												{selectedLayer.traits?.pattern?.isRare ? <span className={'is-rare'}>RARE</span> : null}
												<div className={'value'}>{selectedLayer.traits.pattern.value}</div>
												<div className={'count'}>{selectedLayer.traits.pattern.count} {selectedLayer.traits.pattern.count === 1 ? 'has' : 'have'} this trait</div>
											</div>
											:
											<div className={`trait unrevealed col-5 offset-1 offset-md-0`}>
												<div className={'desc'}>PATTERN</div>
												<div className={'value'}>Unrevealed</div>
												<div className={'count'}>-- have this trait</div>
											</div>
										}
										{selectedLayer && selectedLayer.traits?.type ?
											<div className={`trait col-5`}>
												<div className={'desc'}>TYPE</div>
												{selectedLayer.traits?.type?.isRare ? <span className={'is-rare'}>RARE</span> : null}
												<div className={'value'}>{selectedLayer.traits.type.value}</div>
												<div className={'count'}>{selectedLayer.traits.type.count} {selectedLayer.traits.type.count === 1 ? 'has' : 'have'} this trait</div>
											</div>
											:
											<div className={`trait unrevealed col-5`}>
												<div className={'desc'}>TYPE</div>
												<div className={'value'}>Unrevealed</div>
												<div className={'count'}>-- have this trait</div>
											</div>
										}
										{selectedLayer && selectedLayer.traits?.genesis ?
											<div className={`trait col-5 offset-1 offset-md-0`}>
												<div className={'desc'}>GENESIS</div>
												{selectedLayer.traits?.genesis?.isRare ? <span className={'is-rare'}>RARE</span> : null}
												<div className={'value'}>{selectedLayer.traits.genesis.value}</div>
												<div className={'count'}>{selectedLayer.traits.genesis.count} {selectedLayer.traits.genesis.count === 1 ? 'has' : 'have'} this trait</div>
											</div>
											:
											<div className={`trait unrevealed col-5 offset-1 offset-md-0`}>
												<div className={'desc'}>GENESIS</div>
												<div className={'value'}>Unrevealed</div>
												<div className={'count'}>-- have this trait</div>
											</div>
										}
										{selectedLayer && selectedLayer.traits?.splash ?
											<div className={`trait col-5`}>
												<div className={'desc'}>SPLASH</div>
												{selectedLayer.traits?.splash?.isRare ? <span className={'is-rare'}>RARE</span> : null}
												<div className={'value'}>{selectedLayer.traits.splash.value}</div>
												<div className={'count'}>{selectedLayer.traits.splash.count} {selectedLayer.traits.splash.count === 1 ? 'has' : 'have'} this trait</div>
											</div>
											:
											<div className={`trait unrevealed col-5`}>
												<div className={'desc'}>SPLASH</div>
												<div className={'value'}>Unrevealed</div>
												<div className={'count'}>-- have this trait</div>
											</div>
										}
									</div>
								</div>

								<div className={'section opensea-btns d-md-none'}>
									<div className={'row'}>
										<div className={'opensea-btn-holder col-10 offset-1'}>
											<div className={'opensea-btn'}>
												<a href={OPENSEA_TEARS_MARKET} target={'_blank'} rel={'noopener'} className={'btn has-icon justify-content-center'}>
													<img src={'/assets/img/icons/icon_opensea_fff.svg'} />
													<span className={'text'}>BUY TEARS</span>
												</a>
											</div>
										</div>
										<div className={'opensea-btn-holder col-10 offset-1'}>
											<div className={'opensea-btn'}>
												<a href={OPENSEA_LAYERS_MARKET} target={'_blank'} rel={'noopener'} className={'btn has-icon justify-content-center'}>
													<img src={'/assets/img/icons/icon_opensea_fff.svg'} />
													<span className={'text'}>BUY LAYERS</span>
												</a>
											</div>
										</div>
									</div>
								</div>

							</div>
						</div>
					</div>


				</section>

			</div>

		</Layout>
	);
};

export default StakingPage;

export const Head: HeadFC = () => (
	<>
		<title>LAYERS - Staking</title>
	</>
);
